.home {
    width: 100%;
    height: auto;
    padding: 3px;
}

.bordered-div {
    padding: 10px;
    border: 8px solid red;
    background-color: yellow;
    max-width: 100%; /* Ensure the div doesn't exceed the screen width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
